<template>
  <div class="shell-box">
    <el-breadcrumb separator="/" class="breadcrumb">
      <el-breadcrumb-item :to="{ path: '/myHome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item
        :to="{
          path: `/routerPolicyManagement/nonCompanyPolicyManagement`,
        }"
        >保单列表</el-breadcrumb-item
      >
      <el-breadcrumb-item>保单{{ policyDetail && policyDetail.id ? '编辑' : '添加'}}</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="form">
      <policyForm
        ref="policyForm"
        :detailData="policyDetail"
        :permissions="policyDetailPermissions"
      />
    </div>
    <div class="operating-box">
      <el-button size="mini" @click="handleBack">返回</el-button>
      <el-button size="mini" type="primary" @click="handleReset" plain
        >重置</el-button
      >
      <!-- <el-button
        size="mini"
        type="warning"
        plain
        @click="showUpload = true"
        v-if="$route.meta.policyType == 1"
        >上传附件</el-button
      > -->
      <el-button size="mini" type="primary" @click="handlePolicyUpdate"
        >提交</el-button
      >
    </div>
    <DragMultipleUpload
      :show.sync="showUpload"
      :dataEcho="dataEcho"
      :fileSzie="10"
      :canDeletOld="false"
      @submitData="handleSubmitAttachment"
    ></DragMultipleUpload>
  </div>
</template>

<script>
import policyForm from "@/views/policyManagement/nonComponents/form.vue";
import { getNonPolicyDetail, nonPolicyUpdate } from "@/api/policy";
import DragMultipleUpload from "@/components/DragMultipleUpload";
import { hasPerms, dataPermissions } from "@/utils/auth";
export default {
  components: { policyForm, DragMultipleUpload },
  data() {
    return {
      policyDetail: {},
      originalPolicyDetail: null,
      showUpload: false,
      dataEcho: {},
      attachmentData: {},
      policyDetailPermissions: {
        agentName: true,
        // "desc": "代理人", +
        applicantMode: true,
        // "desc": "投保方式", +
        lastPolicyNo: true,
        // "desc": "上一年保单号", +
        endDate: true,
        // "desc": "到期时间", +
        city: true,
        // "desc": "所属地区", +
        policyNo: true,
        // "desc": "保单号", +
        province: true,
        // "desc": "所属省份", +
        applicantType: true,
        // "desc": "投保类型", +
        acceptCompanyList: true,
        // "desc": "承保公司", +
        extensionClause: true,
        // "desc": "免费扩展条款",+
        specialAgreement: true,
        // "desc": "特别约定",+
        applicantStore: true,
        // "desc": "投保门店",+
        absoluteDeductible3: true,
        // "desc": "未扩展不计免赔",+
        absoluteDeductible2: true,
        // "desc": "扩展不计免赔2",+
        salesmanId: true,
        // "desc": "业务员",+
        agentNo: true,
        // "desc": "代理人编码",+
        beneficiaryList: true,
        // "desc": "受益人",+
        policyHolderList: true,
        // "desc": "投保人",+
        totalPremium: true,
        // "desc": "总保费",+
        ep: true,
        // "desc": "电子保单",+
        projectRemark: true,
        // "desc": "项目备注",+
        policyPlanList: true,
        // "desc": "保障计划",+
        policySubjectList: true,
        // "desc": "标的信息",+
        absoluteDeductible: true,
        // "desc": "扩展不计免赔1",+
        policyInsuredList: true,
        // "desc": "被保险人",+
        projectName: true,
        // "desc": "项目名称",+
        startDate: true,
        // "desc": "起保时间",+
        enquiryNo: true,
        // "desc": "询价单号",+
        recorder: true,
        // "desc": "录单员",
        specialVersion: true,
        // "desc": "特别说明",
        bindUser: true,
        // 绑定客户
        insuranceOfDate: true,
        // 投保日期
        sourceChannel: true,
        // 业务渠道
        upstreamAgent: true,
        // 业务代理人
        // 险种类型
        insuranceTypeName: true,
      },
      ocrData: null
    };
  },
  created() {
    if (this.$route.params.id) {
      this.getDetail();
    }else if(this.$route.query.ocrData) {
      this.ocrData = JSON.parse(this.$route.query.ocrData);
      let policyDetail = JSON.parse(this.$route.query.ocrData);
      policyDetail.premiumItax = policyDetail.totalPremium;
      if (policyDetail.insuredCardNo) {
        policyDetail.policyInsuredList = [{
                  cardEnd: '',
                  cardNo: policyDetail.insuredCardNo || "",
                  cardStart: '',
                  cardType: 1,
                  city: '',
                  contactEmail: '',
                  contactName: policyDetail.beneficiaryName || '',
                  contactPhone: '',
                  county: '',
                  detailAddress: policyDetail.insuredAddr || '',
                  playersName: policyDetail.insuredName || '',
                  playersType: policyDetail.insuredCardType && policyDetail.insuredCardType.indexOf('身份证') > -1 ? 2 : 1,
                  province: '',
                  birthday: '',
                  sex: true,
                  hasSb: true,
                  postResponsibilities: '',
                  jobCategory: '',
                  jobCategoryDesc: '',
        }];
      }
      this.policyDetail = policyDetail;
    }
  },
  methods: {
    getDetail() {
      getNonPolicyDetail({
        id: this.$route.params.id,
      })
        .then((res) => {
          this.policyDetail = res.data;
          this.originalPolicyDetail = JSON.parse(JSON.stringify(res.data));
          let dataBackShow = [];
          let idInd = 1;
          res.data.attachments
            ? Object.keys(res.data.attachments).forEach(function (key) {
                let type = (key + "").split("-");
                dataBackShow.push({
                  type: type[0] == "qt" ? undefined : type[0],
                  selectValue: Number(type[1] || 0),
                  urls: res.data.attachments[key].map((val) => {
                    idInd++;
                    return {
                      suffix: val.fileType,
                      name: val.fileName,
                      url: val.url,
                      id: idInd,
                    };
                  }),
                });
              })
            : res.data.attachments = {
          kpxx: [],
          qtxx: [],
          zjxx: [],
        };
          this.dataEcho = dataBackShow;
          this.$forceUpdate();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleBack() {
      this.$router.go(-1);
    },
    async handlePolicyUpdate() {
      let data = await this.$refs.policyForm.handleSubmit();
      data = this.handleSequence(data, [
        "beneficiaryList",
        "policyHolderList",
        "policyInsuredList",
      ]);
      data.category = 1;
      if (this.attachmentData.attachments) {
        let attachmentType = [];
        for (const key in this.attachmentData.attachments) {
          attachmentType.push(key);
        }
        data.attachmentType = attachmentType.toString();
      } else {
        data.attachmentType = "";
      }
      nonPolicyUpdate({ ...data, ...this.attachmentData }).then((res) => {
        this.handleBack();
        this.$message.success("编辑成功");
      });
    },
    handleReset() {
      this.attachmentData = {};
      this.policyDetail = JSON.parse(JSON.stringify(this.originalPolicyDetail));
      let dataBackShow = {};
      let idInd = 1;
      for (const key in this.policyDetail.attachments) {
        let type = (key + "").split("-");
        dataBackShow[type[0]] = {
          type: type[0] == "qt" ? undefined : type[0],
          selectValue: Number(type[1]),
          urls: this.policyDetail.attachments[key].map((val) => {
            idInd++;
            return {
              suffix: val.fileType,
              name: val.fileName,
              url: val.url,
              id: idInd,
            };
          }),
        };
      }
      this.dataEcho = dataBackShow;
      this.$forceUpdate();
    },
    handleSubmitAttachment(e) {
      let attachment = {};
      let attachmentType = [];
      let qtInd = 1;
      e.map((val) => {
        if (val.type) {
          attachmentType.push(val.type + "-" + val.selectValue);
          attachment[val.type + "-" + val.selectValue] = val.urls.map(
            (item) => {
              return {
                attachmentType: val.type + "-" + val.selectValue,
                fileName: item.name,
                fileType: item.suffix,
                url: item.url,
              };
            }
          );
        } else {
          attachmentType.push("qt" + qtInd + "-" + val.selectValue);
          attachment["qt" + qtInd + "-" + val.selectValue] = val.urls.map(
            (item) => {
              return {
                attachmentType: val.type + "-" + val.selectValue,
                fileName: item.name,
                fileType: item.suffix,
                url: item.url,
              };
            }
          );
          qtInd++;
        }
      });
      this.attachmentData = {
        attachments: attachment,
        attachmentType: attachmentType.toString(),
      };
      this.showUpload = false;
    },
    handleSequence(obj = {}, list = [], sort = "sort") {
      if (list && list.length) {
        list.forEach((item) => {
          if (obj[item].length) {
            obj[item].forEach((p, i) => {
              p[sort] = i;
            });
          }
        });
      }
      return obj;
    },
  },
};
</script>

<style lang="scss" scoped>
.shell-box {
  display: flex;
  flex-direction: column;
  height: 100%;
  .form {
    flex: 1;
    overflow: hidden;
  }
  .breadcrumb {
    padding: 10px 0;
    ::v-deep .el-breadcrumb__inner.is-link {
      color: rgba(0, 128, 255, 1);
    }
  }
  .operating-box {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: calc(100% - 196px);
    height: 50px;
    padding: 0 24px;
    background: #ffffff;
    box-shadow: 0px -3px 6px 1px rgba(0, 0, 0, 0.16);
    border-radius: 8px;
    z-index: 99;
  }
}
</style>
